define("shared/llc/tax-business-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BUSINESS_TYPES_ENUMS = _exports.BUSINESS_TYPES = _exports.APRIL_ENTITIES = void 0;
  const BUSINESS_TYPES = _exports.BUSINESS_TYPES = {
    LLC: 'LLC',
    SOLE_PROP: 'None/sole proprietorship',
    CORP: 'C-corp',
    SCORP: 'S-corp',
    PARTNERSHIP: 'Partnership'
  };
  const APRIL_ENTITIES = _exports.APRIL_ENTITIES = {
    SINGLE_LLC: 'Single-Member LLC',
    MULTI_LLC: 'Multi-Member LLC',
    SOLE_PROP: BUSINESS_TYPES.SOLE_PROP
  };
  const BUSINESS_TYPES_ENUMS = _exports.BUSINESS_TYPES_ENUMS = {
    [APRIL_ENTITIES.SINGLE_LLC]: 'single_member_llc',
    [APRIL_ENTITIES.MULTI_LLC]: 'multi_member_llc',
    [APRIL_ENTITIES.SOLE_PROP]: 'sole_prop',
    [BUSINESS_TYPES.LLC]: 'llc',
    [BUSINESS_TYPES.CORP]: 'c_corp',
    [BUSINESS_TYPES.SCORP]: 's_corp',
    [BUSINESS_TYPES.PARTNERSHIP]: 'partnership'
  };
  var _default = _exports.default = BUSINESS_TYPES;
});