define("shared/llc/notary-items-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BASIC_FEE_ITEM_CODE = _exports.ADDITIONAL_SIGNER_FEE_ITEM_CODE = void 0;
  const BASIC_FEE_ITEM_CODE = _exports.BASIC_FEE_ITEM_CODE = 'virtual_address_notary_basic_fee';
  const ADDITIONAL_SIGNER_FEE_ITEM_CODE = _exports.ADDITIONAL_SIGNER_FEE_ITEM_CODE = 'virtual_address_notary_additional_signer_fee';
  var _default = _exports.default = {
    BASIC_FEE_ITEM_CODE,
    ADDITIONAL_SIGNER_FEE_ITEM_CODE
  };
});